import React, { useEffect, useState } from "react";
import { Button, Input, Pagination, Stack, Table, TextInput } from "@mantine/core";
import { getAllStudentDetails, getAllStudentDetailsByEmail } from "../../Query/DashboardAdmin";
import { Link } from "react-router-dom";
import AvinyaLoader from "../Loader/AvinyaLoader";
import StudentScoreComponent from "./StudentScoreComponent";
import toast from "react-hot-toast";
import { Appbar } from "../Appbar/Appbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Loader } from "lucide-react";

const AdminDashboard = () => {
  const [studentData, setStudentData] = useState([]);
  const [searchStudentData, setSearchStudentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const pageSize = 5;
  const [pageNumber, setPageNumber] = useState(1);
  const startingIndex = (pageNumber - 1) * pageSize;
  const pageData = searchStudentData.length > 0 
    ? searchStudentData.slice(startingIndex, startingIndex + pageSize) 
    : studentData.slice(startingIndex, startingIndex + pageSize);

  const handlePageChange = (newPage) => {
    setPageNumber(newPage);
  };

  const filter = (email) => {
    setSearchStudentData(studentData.filter((student) => 
      student.email.toLowerCase().includes(email.toLowerCase())
    ));
  };

  const fetchData = async () => {
    try {
      // First get all students score data
      const scoreData = await getAllStudentDetails();
      
      // For each student in score data, fetch their details using email
      const enrichedData = await Promise.all(
        scoreData.map(async (scoreItem) => {
          try {
            const detailsData = await getAllStudentDetailsByEmail(scoreItem.email);
            // Combine score data with student details
            return {
              ...scoreItem,
              studentName: detailsData?.studentName || 'N/A',
              grade: detailsData?.grade || 'N/A',
              phoneNumber: detailsData?.phoneNumber || 'N/A',
              schoolName: detailsData?.schoolName || 'N/A',
              address: detailsData?.address || 'N/A',
              board: detailsData?.board || 'N/A',
              image: detailsData?.image || '',
              registeredTime: detailsData?.registeredTime || 'N/A',
            };
          } catch (error) {
            console.error(`Error fetching details for ${scoreItem.email}:`, error);
            // Return score data with default values if details fetch fails
            return {
              ...scoreItem,
              studentName: 'N/A',
              grade: 'N/A',
              phoneNumber: 'N/A',
              schoolName: 'N/A',
              address: 'N/A',
              board: 'N/A',
              image: '',
              registeredTime: 'N/A',
            };
          }
        })
      );

      setStudentData(enrichedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching student data:', error);
      toast.error('Failed to load student data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const rows = pageData?.map((element) => (
    <Table.Tr key={element.email}>
      <Table.Td>
        {element.image && (
          <img
            src={element.image}
            height={50}
            width={80}
            style={{ objectFit: "contain" }}
            alt={element.studentName}
          />
        )}
      </Table.Td>
      <Table.Td>{element.studentName}</Table.Td>
      <Table.Td>{element.email}</Table.Td>
      <Table.Td>{element.grade}</Table.Td>
      <Table.Td>{element.board}</Table.Td>
      <Table.Td>{element.phoneNumber}</Table.Td>
      <Table.Td>{element.schoolName}</Table.Td>
      <Table.Td>{element.address}</Table.Td>
      <Table.Td>{element.registeredTime?.substring(11) || 'N/A'}</Table.Td>
      <Table.Td>{element.registeredTime?.substring(0, 10) || 'N/A'}</Table.Td>
      <Table.Td>{element.maxMarks}</Table.Td>
      <Table.Td>{element.marksObtained}</Table.Td>
      <Table.Td>{element.timeTaken}</Table.Td>
      <Table.Td>{element.org}</Table.Td>
    </Table.Tr>
  ));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "10px",
          }}
        >
          <Stack
            bg=""
            style={{
              borderRadius: "6px",
              boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
            }}
            p={10}
            w={"85%"}
            maw={{ xs: "100%", sm: "100%", md: "100%", xl: "90%" }}
          >
            <nav
              style={{
                display: "flex",
                textDecoration: "none",
                listStyleType: "none",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "0.2rem",
              }}
            >
              <Link to={"/addquestion"}>
                <Button mt="sm" w={{ base: "100%" }} variant="gradient">
                  Add questions
                </Button>
              </Link>
              <Link to={"/deletQuestion"}>
                <Button mt="sm" w={{ base: "100%" }} variant="gradient">
                  View questions
                </Button>
              </Link>
              <TextInput 
                placeholder="Search by email" 
                mt={"sm"} 
                onChange={(e) => filter(e.target.value)}
              />
            </nav>

            <div style={{ marginTop: "1.2rem" }}>
              <Table.ScrollContainer minWidth={500}>
                <Table
                  striped
                  highlightOnHover
                  withTableBorder
                  withColumnBorders
                  horizontalSpacing="lg"
                  verticalSpacing="sm"
                >
                  <Table.Thead>
                    <Table.Tr c={"cyan.9"}>
                      <Table.Th>Image</Table.Th>
                      <Table.Th>Name</Table.Th>
                      <Table.Th>Email</Table.Th>
                      <Table.Th>Grade</Table.Th>
                      <Table.Th>Board</Table.Th>
                      <Table.Th>Phone number</Table.Th>
                      <Table.Th>School Name</Table.Th>
                      <Table.Th>Address</Table.Th>
                      <Table.Th>Registered Time</Table.Th>
                      <Table.Th>Registered Date</Table.Th>
                      <Table.Th>Max Marks</Table.Th>
                      <Table.Th>Marks Obtained</Table.Th>
                      <Table.Th>Time Taken</Table.Th>
                      <Table.Th>Organization</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>{rows}</Table.Tbody>
                </Table>
              </Table.ScrollContainer>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Pagination
                total={Math.ceil(studentData?.length / pageSize)}
                withEdges
                page={pageNumber}
                onChange={handlePageChange}
                styles={() => ({
                  item: {
                    background: "none",
                    border: "1px solid gray",
                  },
                })}
              />
            </div>
          </Stack>
        </div>
      )}
    </DashboardLayout>
  );
};

export default AdminDashboard;