/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Grid from "@mui/material/Grid";
// import SoftBox from "components/SoftBox";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import Footer from "examples/Footer";
// import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
// import Header from "layouts/profile/components/Header";
// import { useEffect, useState } from "react";
// import Swal from "sweetalert2";
// import { org } from "authentication/APIData";
// import { APIData } from "authentication/APIData";

// const formatDate = (dateString) => {
//   const date = new Date(dateString);
//   const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
//   const day = String(date.getDate()).padStart(2, '0');
//   const year = date.getFullYear();
//   return `${month}-${day}-${year}`;
// };

// function Overview() {
//   const [profileInfo, setProfileData] = useState({});
//   const [userdata, setUserData] = useState();



//   useEffect(() => {
//     const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
//     setUserData(userData_Local);
//     console.log(userData_Local);
//   }, []);

//   useEffect(() => {
//     if (userdata) {
//       const fetchData = async () => {
//         try {
//           let url = `${APIData.api}employee/${userdata.userName}`;
//           const response = await fetch(url, { headers: APIData.headers });
//           console.log(response);

//           const data = await response.json();
//           setProfileData(data);
//         } catch (error) {
//           Swal.fire({
//             title: "Error!",
//             text: `Failed to fetch "${org}" details`,
//             icon: "error",
//             confirmButtonText: "OK",
//           });
//         }
//       };

//       fetchData();
//     }
//   }, [userdata]);



//   return (
//     <DashboardLayout>
//       <Header />
//       <SoftBox mt={5} mb={3}>
//         <Grid container spacing={3}>
//           <Grid item xs={12} md={6} xl={4}>
//             <ProfileInfoCard
//               title="profile information"
//               info={{
//                 fullName: `${profileInfo.name ? profileInfo.name.charAt(0).toUpperCase() + profileInfo.name.slice(1).toLowerCase() : "Name"}`,
//                 mobile: `${profileInfo.phone_number}`,
//                 email: `${profileInfo.officeEmail || profileInfo.email}`,
//                 location: `${profileInfo.address}`,
//                 DOB: `${formatDate(profileInfo.dob)}`,
//                 joinedDate: `${formatDate(profileInfo.year_of_appointment)}`,
//                 Qualification: `${profileInfo.qualification}`,
//                 gender: `${profileInfo.gender}`,
//               }}

//               action={{ route: `/edit-profile/${profileInfo.employee_id}`, tooltip: "Edit Profile" }}
//             />
//           </Grid>

//           <Grid item xs={12} md={6} xl={4}>
//             <ProfileInfoCard
//               title="Department Information"
//               info={{
//                 Department: profileInfo.dept ? profileInfo.dept[0] : 'N/A',
//                 Designation: profileInfo.role_privileges && profileInfo.role_privileges[0] ? profileInfo.role_privileges[0].designation : 'N/A',
//                 Role: profileInfo.role_privileges && profileInfo.role_privileges[0] ? profileInfo.role_privileges[0].role : 'N/A',
//                 Supervisor: profileInfo.supervisor ? profileInfo.supervisor[0] : 'N/A',
//                 Branch: profileInfo.branch
//               }}
//               action={{}}
//               showAction={false}
//             />
//           </Grid>

//         </Grid>
//       </SoftBox>


//       <Footer />
//     </DashboardLayout>
//   );
// }

// export default Overview;

import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  Button,
  Card,
  CardContent
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SchoolIcon from '@mui/icons-material/School';
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import Header from "layouts/profile/components/Header";
import Swal from "sweetalert2";
import { org } from "authentication/APIData";
import { APIData } from "authentication/APIData";

// Import ID Card Components
import IdCard1 from 'layouts/idCards/idCard1';
import IdCard2 from 'layouts/idCards/idCard2';
import IdCard3 from 'layouts/idCards/idCard3';
import IdCard4 from 'layouts/idCards/idCard4';
import IdCard5 from 'layouts/idCards/idCard5';

// Utility function to format date
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

// Detailed Information Display Component
const InformationDisplay = ({ data, onEdit }) => {
  return (
    <div style={{ 
      display: 'grid', 
      gridTemplateColumns: 'repeat(2, 1fr)', 
      gap: '16px', 
      padding: '16px'
    }}>
      {Object.entries(data).map(([key, value]) => (
        <div key={key} style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px',
          borderBottom: '1px solid #e0e0e0',
          width:"100%"
        }}>
          <Typography 
            variant="body2" 
            color="textSecondary" 
            style={{ 
              fontWeight: 600, 
              marginRight: '16px',
              minWidth: '120px' 
            }}
          >
            {key}
          </Typography>
          <Typography 
            variant="body2" 
            style={{ 
              flex: 1, 
              textAlign: 'right' 
            }}
          >
            {value || 'N/A'}
          </Typography>
        </div>
      ))}
      {onEdit && (
        <div style={{ 
          gridColumn: '1 / -1', 
          textAlign: 'right', 
          marginTop: '16px' 
        }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={onEdit}
          >
            Edit Profile
          </Button>
        </div>
      )}
    </div>
  );
};

InformationDisplay.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func
};

// Compact Card Component
const CompactInfoCard = ({ title, icon, onExpand }) => {
  return (
    <Card 
      style={{ 
        height: '160px', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        cursor: 'pointer',
        transition: 'transform 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)'
        }
      }}
      onClick={onExpand}
    >
      <CardContent style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center',
        textAlign: 'center'
      }}>
        {icon}
        <Typography 
          variant="h6" 
          style={{ 
            marginTop: '12px', 
            color: '#344767', 
            fontWeight: 600 
          }}
        >
          {title}
        </Typography>
        <Typography 
          variant="body2" 
          style={{ 
            color: '#7b809a', 
            marginTop: '8px' 
          }}
        >
          Click to view details
        </Typography>
      </CardContent>
    </Card>
  );
};

CompactInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onExpand: PropTypes.func.isRequired
};

// Detailed Information Dialog
const DetailDialog = ({ open, onClose, title, children }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: '12px'
        }
      }}
    >
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">{title}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
};

DetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};

// Separate ID Card Display Component
const EmployeeIdCard = ({ orgDetails, profileInfo }) => {
  const renderIdCard = () => {
    const props = {
      name: profileInfo.name || 'Employee Name',
      dob: formatDate(profileInfo.dob),
      id: profileInfo.employee_id,
      photo: profileInfo.photoUrl,
      role: profileInfo.access_profiles,
      orgName: orgDetails.orgName,
      orgAddress: orgDetails.orgAddress,
      logoUrl: orgDetails.logoUrl
    };

    switch (orgDetails.idCardTemplate) {
      case "1": return <IdCard1 {...props} />;
      case "2": return <IdCard2 {...props} />;
      case "3": return <IdCard3 {...props} />;
      case "4": return <IdCard4 {...props} />;
      case "5": return <IdCard5 {...props} />;
      default: return <IdCard1 {...props} />;
    }
  };

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      width: '100%' 
    }}>
      {renderIdCard()}
    </div>
  );
};

EmployeeIdCard.propTypes = {
  orgDetails: PropTypes.object.isRequired,
  profileInfo: PropTypes.object.isRequired
};

function Overview() {
  const navigate = useNavigate();
  const [profileInfo, setProfileData] = useState({});
  const [userdata, setUserData] = useState();
  const [orgDetails, setOrgDetails] = useState({
    orgName: '',
    orgAddress: '',
    logoUrl: '',
    idCardTemplate: '1'
  });

  // State for managing dialogs
  const [openDialogs, setOpenDialogs] = useState({
    personalInfo: false,
    departmentInfo: false,
    idCard: false,
    qualificationInfo: false,
    documentInfo: false
  });

  useEffect(() => {
    const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
    setUserData(userData_Local);
  }, []);

  useEffect(() => {
    if (userdata) {
      const fetchProfileData = async () => {
        try {
          let url = `${APIData.api}employee/${userdata.userName}`;
          const response = await fetch(url, { headers: APIData.headers });
          const data = await response.json();
          setProfileData(data);
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: `Failed to fetch "${org}" details`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      };

      const fetchOrgData = async () => {
        try {
          const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`, {
            headers: APIData.headers
          });
          const orgData = await response.json();
          setOrgDetails({
            orgName: orgData.orgName || '',
            orgAddress: orgData.orgAddress || '',
            logoUrl: orgData.orgLogo || '',
            idCardTemplate: orgData.idCardTemplate || '1'
          });
        } catch (error) {
          console.error("Error fetching organization data:", error);
        }
      };

      fetchProfileData();
      fetchOrgData();
    }
  }, [userdata]);

  // Function to toggle dialogs
  const toggleDialog = (dialogName) => {
    setOpenDialogs(prev => ({
      ...prev,
      [dialogName]: !prev[dialogName]
    }));
  };

  // Handle Edit Profile Navigation
  const handleEditProfile = () => {
    navigate(`/edit-profile/${profileInfo.employee_id}`);
  };

  return (
    <DashboardLayout>
      <Header />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          {/* Personal Information Compact Card */}
          <Grid item xs={12} md={4}>
            <CompactInfoCard
              title="Personal Information"
              icon={<AccountCircleIcon color="primary" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('personalInfo')}
            />
            <DetailDialog
              open={openDialogs.personalInfo}
              onClose={() => toggleDialog('personalInfo')}
              title="Personal Information"
            >
              <InformationDisplay
                data={{
                  'Full Name': `${profileInfo.name ? profileInfo.name.charAt(0).toUpperCase() + profileInfo.name.slice(1).toLowerCase() : "Name"}`,
                  'Mobile': profileInfo.phone_number,
                  'Email': profileInfo.officeEmail || profileInfo.email,
                  'Location': profileInfo.address,
                  'Date of Birth': formatDate(profileInfo.dob),
                  'Joined Date': formatDate(profileInfo.year_of_appointment),
                  'Gender': profileInfo.gender,
                }}
                onEdit={handleEditProfile}
              />
            </DetailDialog>
          </Grid>

          {/* Department Information Compact Card */}
          <Grid item xs={12} md={4}>
            <CompactInfoCard
              title="Department Information"
              icon={<WorkIcon color="primary" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('departmentInfo')}
            />
            <DetailDialog
              open={openDialogs.departmentInfo}
              onClose={() => toggleDialog('departmentInfo')}
              title="Department Information"
            >
              <InformationDisplay
                data={{
                  'Department': profileInfo.dept ? profileInfo.dept[0] : 'N/A',
                  'Designation': profileInfo.role_privileges && profileInfo.role_privileges[0] ? profileInfo.role_privileges[0].designation : 'N/A',
                  'Role': profileInfo.role_privileges && profileInfo.role_privileges[0] ? profileInfo.role_privileges[0].role : 'N/A',
                  'Supervisor': profileInfo.supervisor ? profileInfo.supervisor[0] : 'N/A',
                  'Branch': profileInfo.branch || 'N/A'
                }}
              />
            </DetailDialog>
          </Grid>

          {/* ID Card Compact Card */}
          <Grid item xs={12} md={4}>
            <CompactInfoCard
              title="ID Card"
              icon={<CreditCardIcon color="primary" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('idCard')}
            />
            <DetailDialog
              open={openDialogs.idCard}
              onClose={() => toggleDialog('idCard')}
              title="Employee ID Card"
            >
              <EmployeeIdCard 
                orgDetails={orgDetails} 
                profileInfo={profileInfo} 
              />
            </DetailDialog>
          </Grid>

          {/* Qualification Information Compact Card */}
          <Grid item xs={12} md={4}>
            <CompactInfoCard
              title="Qualification Information"
              icon={<SchoolIcon color="primary" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('qualificationInfo')}
            />
            <DetailDialog
              open={openDialogs.qualificationInfo}
              onClose={() => toggleDialog('qualificationInfo')}
              title="Qualification Information"
            >
              <InformationDisplay
                data={{
                  'Highest Qualification': profileInfo.qualification || 'N/A',
                  'Educational Institution': profileInfo.educationalInstitution || 'N/A',
                  'Graduation Year': profileInfo.graduationYear || 'N/A',
                  'Additional Certifications': profileInfo.certifications || 'N/A'
                }}
              />
            </DetailDialog>
          </Grid>

          {/* Document Information Compact Card */}
          <Grid item xs={12} md={4}>
            <CompactInfoCard
              title="Document Information"
              icon={<DescriptionIcon color="primary" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('documentInfo')}
            />
            <DetailDialog
              open={openDialogs.documentInfo}
              onClose={() => toggleDialog('documentInfo')}
              title="Document Information"
            >
              <InformationDisplay
                data={{
                  'PAN Number': profileInfo.panNumber || 'N/A',
                  'Aadhaar Number': profileInfo.aadhaarNumber || 'N/A',
                  'Passport Number': profileInfo.passportNumber || 'N/A',
                  'Driving License': profileInfo.drivingLicense || 'N/A'
                }}
              />
            </DetailDialog>
          </Grid>
          {/* Document Information Compact Card */}
          <Grid item xs={12} md={4}>
            <CompactInfoCard
              title="Attedence Information"
              icon={<SchoolIcon color="primary" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('attedenceInfo')}
            />
            <DetailDialog
              open={openDialogs.documentInfo}
              onClose={() => toggleDialog('attedenceInfo')}
              title="Attedence Information"
            >
              <InformationDisplay
                data={{
                  'PAN Number': profileInfo.panNumber || 'N/A',
                  'Aadhaar Number': profileInfo.aadhaarNumber || 'N/A',
                  'Passport Number': profileInfo.passportNumber || 'N/A',
                  'Driving License': profileInfo.drivingLicense || 'N/A'
                }}
              />
            </DetailDialog>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;